module.exports = {
  languages: ['en', 'ru', 'es'],
  currentLang: 'es',
  domain: 'veliky.net',
  loaderText: 'https://es.veliky.net',
  indexTitle: 'Eugenio Veliky - Desarrollador de Software',
  role: 'Función',
  readMore: 'Abrir >>',
  close: 'x Cerrar',
  linkTo: 'Enlace a',
  meta: {
    desc: 'Eugenio Veliky es un Ingeniero de Software Senior con amplia experiencia en la creación de sitios web',
    keywords: 'Front End, Full Stack, Vue, Wordpress, JavaScript, PHP, MySQL',
    title: 'Eugenio Veliky - Desarrollador de Software',
    type: 'website',
    image: 'icon.png',
  },
  header: {
    professions: 'Desarrollador de Software',
    name: 'Eugenio Veliky',
    skillsOn: '> Mis habilidades <',
    skillsOff: 'x Ocultar x',
    links: {
      github: 'https://github.com/veliky/',
      linkedin: 'https://www.linkedin.com/in/eugenegreat/',
      share: '',
    },
  },
  experience: {
    title: 'Mi trabajo',
    desc: ['Hago páginas web desde 2008.', 'Desde 2014 profesionalmente.'],
    companies: {
      huumun: {
        name: 'Huumun',
        title: ['Proveedor', 'de Soluciones', 'Digitales'],
        role: 'Ingeniero de Software Sénior',
        content: '&emsp;Huumun es una empresa establecida del Reino Unido con una larga trayectoria. Especializada en soluciones digitales en el sector de las ciencias de la vida. Entre sus clientes se encuentran empresas farmacéuticas de fama mundial. Mientras trabajaba en el equipo de desarrollo, pude completar un par de proyectos interesantes para Jazz Pharmaceutical y Pfizer.',
      },
      smarteka: {
        name: 'Smarteka.ru',
        title: ['Plataforma', 'para Proyectos', 'Sociales'],
        role: 'Desarrollador Principal',
        content: '&emsp;Smarteka.com es una plataforma popular para proyectos sociales en muchas áreas como salud, cultura, medio ambiente, educación, desarrollo empresarial y otras. La funcionalidad de la plataforma incluye la aceptación de los materiales de solicitud, la moderación por parte de expertos de la industria, la publicación de los proyectos aprobados y sus resultados.<br>' +
                '&emsp;Como Desarrollador Principal, desarrollé nuevas partes del proyecto, completé un rediseño completo del proyecto y colaboré con otros miembros del equipo de desarrollo.',
      },
      socialbet: {
        name: 'Social.bet',
        title: ['Plataforma', 'para Concursos', 'Gratuitas'],
        role: 'Desarrollador Principal',
        content: '&emsp;Social.bet era una startup cuyo objetivo eran las competiciones para aficionados al deporte. El proyecto proporcionó una amplia gama de funcionalidades de juego, incluidas probabilidades para eventos de 10 casas de apuestas diferentes, la capacidad de realizar apuestas por la moneda del juego y realizar un seguimiento de las estadísticas, diferentes tipos de apuestas y tipos de competiciones.<br>' +
                '&emsp;Como Desarrollador Principal, participé en el desarrollo desde la etapa inicial del proyecto, durante todo el proyecto estuve involucrado en la creación y desarrollo de funcionalidades tanto en el front-end como en el back-end.<br>' +
                '&emsp;Desafortunadamente, el proyecto se cerró durante la reducción de costos de Covid.',
      },
      ignet: {
        name: 'Ignet',
        title: ['Estudio de', 'Desarrollo Web', 'Ignet Solutions'],
        role: 'Desarrollador de Software',
        content: '&emsp;Ignet era un estudio de desarrollo de sitios web dinámico. Nos especializamos en proyectos complejos implementados en WordPress. Algunas de nuestras tareas favoritas incluían la entrada y salida de grandes cantidades de datos, optimización de la velocidad, plantillas responsivas y, finalmente, la creación de innumerables complementos y temas personalizados para trabajar con una amplia variedad de API y tipos de contenido.<br>' +
                '&emsp;Esta pequeña empresa me dio un fuerte comienzo en forma de experiencia trabajando con diferentes tipos de clientes y proyectos en diferentes áreas. Durante casi 3 años, he lanzado más de 10 sitios web desde cero y he participado en el trabajo de más de 30.',
      }
    },
    pets: {
      title: 'Proyectos propios >>',
      back: '<< Atrás',
      list: {
        'embed-mixcloud-advanced': {
          title: ['Wordpress', 'Plugin'],
          name: 'Embed Mixcloud Advanced',
          content: '&emsp;Siempre me ha interesado escuchar música y crear proyectos. Esta vez condujo a la exitosa experiencia de publicar el complemento en el repositorio oficial de WordPress.<br>' +
            '&emsp;La funcionalidad del proyecto era específica para los fanáticos de la plataforma de música mixcloud.com. El proyecto no fue popular y decidí cerrarlo.',
        },
        'iframe-new-tab': {
          title: ['Chrome', 'Extension'],
          name: 'Iframe New Tab',
          content: '&emsp;Hace tiempo que quería intentar crear una extensión para el navegador y cuando surgió la idea correcta lo hice. La funcionalidad no es única ni primitiva, pero para mí fue una experiencia de desarrollo y publicación para una plataforma específica. ¡La extensión todavía está viva y se puede instalar incluso ahora!',
        },
        'spaze': {
          title: ['Sitio', 'movil'],
          name: 'Spaze.ru',
          content: '&emsp;Este fue mi primer proyecto con un dominio de primer nivel. Era de carácter de referencia, dedicado a los usuarios de una red social móvil. No era muy conocido pero tenía una pequeña audiencia estable. Actualmente puedes ver fragmentos de sitios en el archivo web.',
        },
        'sehack': {
          title: ['Sitio', 'movil'],
          name: 'se-hack.wen.su',
          content: '&emsp;Creé mi primer sitio web en 2008 usando un teléfono con una resolución de pantalla de 128x128. Y aunque fue creado usando un creador de sitios web primitivo, ya fui más allá y aprendí a usar HTML y CSS. ¡Y estaba disponible en Internet! Así que mis amigos y yo competimos para ver cuál sitio era más genial.<br>' +
            '&emsp;Desafortunadamente, el proveedor de alojamiento cerró y el sitio ya no está disponible.',
        },
      },
    },
  },
  skills: [
    'JavaScript',
    'Vue',
    'Nuxt.js',
    'Webpack',
    'SASS',
    'SCSS',
    'D3.js',
    'SVG',
    'BEM',
    'CSS3',
    'Photoshop',
    'Figma',
    'HTML5',
    'PHP',
    'MySQL',
    'WordPress',
    'WooCommerce',
    'Gutenberg',
    'WP-CLI',
    'Composer',
    'OOP',
    'Linux',
    'Localization',
    'REST APIs',
    'Payment APIs',
    'CRON',
    'GitLab',
    'Jira',
    'PhpStorm',
    'Slack',
    'Trello',
    'Software Engineering',
    'Android Development',
    'xDebug'
  ],
  hobbies: {
    btn: '<< Mis Aficiones',
    gallery: {
      title: 'Me gusta tomar fotos',
    },
    player: {
      title: 'Y escuchar',
      subtitle: 'Deep House música',
      prev: 'Previo',
      play: 'Jugar',
      next: 'Proximo',
      toggle: 'Revelar o Minimizar',
      info: {
        title: 'Descarga...',
      }
    }
  }
}
