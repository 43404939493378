module.exports = {
  languages: ['en', 'ru', 'es'],
  currentLang: 'ru',
  domain: 'veliky.net',
  loaderText: 'https://ru.veliky.net',
  indexTitle: 'Евгений Великий - Разработчик ПО',
  role: 'Роль',
  readMore: 'Открыть >>',
  close: 'x Закрыть',
  linkTo: 'Ссылка на',
  meta: {
    desc: 'Евгений Великий это Старший Инжинер-Программист с богатым опытом в создании сайтов',
    keywords: 'Front End, Full Stack, Vue, Wordpress, JavaScript, PHP, MySQL',
    title: 'Евгений Великий - Разработчик ПО',
    type: 'website',
    image: 'icon.png',
  },
  header: {
    professions: 'Инженер-Программист',
    name: 'Евген Великий',
    skillsOn: '> Мои навыки <',
    skillsOff: 'x Скрыть x',
    links: {
      github: 'https://github.com/veliky/',
      linkedin: 'https://www.linkedin.com/in/eugenegreat/',
      share: '',
    },
  },
  experience: {
    title: 'Моя работа',
    desc: ['Делаю сайты с 2008.', 'С 2014 профессионально.'],
    companies: {
      huumun: {
        name: 'Huumun',
        title: ['Поставщик', 'цифровых', 'решений'],
        role: 'Старший Инженер-Программист',
        content: '&emsp;Huumun это устоявшаяся компания из Великобритании с длинной историей. Специализируется на цифровых решениях в сфере гуманитарных наук. Среди их клиентов компании с мировым именем. Во время работы в команде разработки, мне удалось выполнить несколько интересных проектов для Jazz Pharmaceutical и Pfizer.',
      },
      smarteka: {
        name: 'Smarteka.ru',
        title: ['Платформа', 'социальных', 'проектов'],
        role: 'Ведущий Разработчик',
        content: '&emsp;Smarteka.com — это популярная платформа для социальных проектов во многих сферах, таких как здравоохранение, культура, экология, образование, развитие предпринимательства и других. Функционал платформы включает прием материалов заявок, модерацию экспертами отрасли, публикацию одобренных проектов и их результатов.<br>' +
                '&emsp;В качестве ведущего разработчика я разработал новые части проекта, выполнил полную редизайн проекта и сотрудничал с другими членами команды разработчиков.',
      },
      socialbet: {
        name: 'Social.bet',
        title: ['Платформа', 'бесплатных', 'конкурсов'],
        role: 'Ведущий разработчик',
        content: '&emsp;Social.bet был стартапом, целью которого были соревнования для любителей спорта. В проекте предусмотрен широкий спектр игрового функционала, включая коэффициенты на события от 10 разных букмекерских контор, возможность делать ставки за игровую валюту и отслеживать статистику, различные виды ставок и виды соревнований.<br>' +
                '&emsp;В качестве ведущего разработчика я принимал участие в разработке с начальной стадии проекта, на протяжении всего проекта занимался созданием и развитием функционала как на фронтенде, так и на бэкенде.<br>' +
                '&emsp;К сожалению, проект был закрыт во время сокращения расходов при Covid.',
      },
      ignet: {
        name: 'Ignet',
        title: ['Веб-Студия', 'Ignet Solutions'],
        role: 'Инжинер-Программист',
        content: '&emsp;Ignet была динамичной студией разработки веб-сайтов. Мы специализировались на сложных проектах, реализованных на WordPress. Некоторые из наших любимых задач включали ввод и вывод больших объемов данных, оптимизацию скорости, адаптивные шаблоны и, наконец, создание бесчисленных пользовательских плагинов и тем для работы с широким спектром API и типов контента.<br>' +
                '&emsp;Эта небольшая компания дала мне хороший старт в виде опыта работы с разными типами клиентов и проектами в разных областях. За почти 3 года я выпустил с нуля более 10 сайтов и принял участие в работе более 30.',
      }
    },
    pets: {
      title: 'Свои проекты >>',
      back: '<< Назад',
      list: {
        'embed-mixcloud-advanced': {
          title: ['Wordpress', 'Плагин'],
          name: 'Embed Mixcloud Advanced',
          content: '&emsp;Мне всегда было интересно слушать музыку и создавать проекты. На этот раз это привело к успешному опыту публикации плагина в официальном репозитории WordPress.<br>' +
            '&emsp;Функционал проекта был специфичен для поклонников музыкальной платформы mixcloud.com. Проект не пользовался популярностью и я решил его закрыть.',
        },
        'iframe-new-tab': {
          title: ['Расширение', 'для Chrome'],
          name: 'Iframe New Tab',
          content: '&emsp;Давно хотел попробовать создать расширение для браузера и когда в голову пришла нужная идея, я это сделал. Функционал не уникален и не примитивен, но для меня это был опыт разработки и публикации под конкретную платформу. Расширение все еще живо и его можно установить уже сейчас!',
        },
        'spaze': {
          title: ['Мобильный', 'сайт'],
          name: 'Spaze.ru',
          content: '&emsp;Это был мой первый проект с доменом первого уровня. Оно носило справочный характер, посвященное пользователям одной мобильной социальной сети. Он не был широко известен, но имел стабильную небольшую аудиторию. На данный момент фрагменты сайтов вы можете увидеть в веб-архиве.',
        },
        'sehack': {
          title: ['Мобильный', 'сайт'],
          name: 'se-hack.wen.su',
          content: '&emsp;Свой первый сайт я создал еще в 2008 году на телефоне с разрешением экрана 128x128. И хотя он был создан с помощью примитивного конструктора сайтов, я уже выходил за рамки и научился использовать HTML и CSS. И это было доступно на весь Интернет! Так что мы с друзьями соревновались чей сайт круче.<br>' +
            '&emsp;К сожалению, хостинг-провайдер закрылся и сайт больше не доступен.',
        },
      },
    },
  },
  skills: [
    'JavaScript',
    'Vue',
    'Nuxt.js',
    'Webpack',
    'SASS',
    'SCSS',
    'D3.js',
    'SVG',
    'BEM',
    'CSS3',
    'Photoshop',
    'Figma',
    'HTML5',
    'PHP',
    'MySQL',
    'WordPress',
    'WooCommerce',
    'Gutenberg',
    'WP-CLI',
    'Composer',
    'OOP',
    'Linux',
    'Localization',
    'REST APIs',
    'Payment APIs',
    'CRON',
    'GitLab',
    'Jira',
    'PhpStorm',
    'Slack',
    'Trello',
    'Software Engineering',
    'Android Development',
    'xDebug'
  ],
  hobbies: {
    btn: '<< Мои хобби',
    gallery: {
      title: 'Я люблю делать фото',
    },
    player: {
      title: 'А так же слушать',
      subtitle: 'Deep House сеты',
      prev: 'Предыдущий',
      play: 'Играть',
      next: 'Следующий',
      toggle: 'Развернуть или Свернуть',
      info: {
        title: 'Загрузка...',
      }
    }
  }
}
