(() => {

  const locals = require('./locals.js');
  const { toggleBodyClass } = require('./utils');

  const experienceHashList = [
    'huumun',
    'smarteka',
    'socialbet',
    'ignet',
    'iframe-new-tab',
    'embed-mixcloud-advanced',
    'spaze',
    'sehack',
  ];

  const petsHashList = [
    'iframe-new-tab',
    'embed-mixcloud-advanced',
    'spaze',
    'sehack',
  ];

  const gridClass = 'evp-exp__grid';
  const petsGridClass = 'evp-exp__grid_pets';
  const itemClass = 'evp-exp__col';
  const hiddenClass = 'evp-exp_hidden';

  /**
   * @return undefined
   */
  const init = () => {

    // Handle header skills button
    const skillBtnOn = document.querySelector('.evp-profile-links__skills_on a');
    const skillBtnOff = document.querySelector('.evp-profile-links__skills_off a');

    skillBtnOn.addEventListener('click', e => {
      toggleBodyClass('evp-t-skills')(e);
                setTimeout(() => skillBtnOff.focus(), 200);
    });
    skillBtnOff.addEventListener('click', e => {
      toggleBodyClass('evp-t-skills')(e);
      setTimeout(() => skillBtnOn.focus(), 200);
    });

    // Share API
    const [ title, text ] = locals.indexTitle.split(' - ');
    const shareData = {
      title,
      text,
      url: locals.loaderText,
    };

    if (navigator.canShare && navigator.canShare(shareData)) {
      const shareLink = document.querySelector('.evp-profile-links__ext-item_share');
      shareLink.style.display = 'block';

      shareLink.addEventListener('click', async e => {
        e.preventDefault();

        try {
          await navigator.share(shareData);
        } catch (err) {
          console.error(err);
        }
      });
    }

    // Navigate page with initial hash
    hashToClass(document.location.hash);

    // Navigate by links
    const boxLinks = document.querySelectorAll('.evp-exp a');

    for (let i = 0; i < boxLinks.length; i++) {
      boxLinks[i].addEventListener('click', (e) => {
        const hash = hashToClass(boxLinks[i].getAttribute('href'));

        // Focus [ Close ] on open
        const closeBtn = document.querySelector('.evp-exp__content_' + hash + ' .evp-exp__content-close');

        if (closeBtn) {
          setTimeout(() => {
            closeBtn.focus();
          }, 200);

          // Focus BOX on close
          closeBtn.addEventListener('click', () => {
            setTimeout(() => {
              boxLinks[i].focus();
            }, 200);
          });
        }
      });
    }

    // Client-side Go back feature
    navigation.addEventListener('navigate', navigateBack);
  }

  /**
   * @param url
   * @return {string}
   */
  const getHash = url => (url || '').split('#')[1];

  /**
   * @param {String} hash
   *
   * @return {String}
   */
  const hashToClass = (hash) => {

    hash = hash ? hash.replace('#', '') : ''
    document.body.className = document.body.className.replace(/\bevp-h-\S+/ig, '');

    if (hash.length > 0) {
      document.body.classList.add('evp-h-' + hash);
      hideExperienceItemsExcept(hash);
      displayExperienceItems(hash);
    } else {
      displayExperienceItems();
    }

    return hash;
  }

  /**
   * @param {{}} event
   */
  const navigateBack = (event) => {
    if ('traverse' === event.navigationType) {
      const hash = event.destination.url.split('#')[1];
      hashToClass(hash);
    }
  }

  /**
   * @param hash
   */
  const hideExperienceItemsExcept = (hash) => {

    if (experienceHashList.indexOf(hash) > -1) {
      let boxListToHide = petsHashList.indexOf(hash) > -1 ?
        document.querySelectorAll(`.${petsGridClass} .${itemClass}:not(.${itemClass}_${hash})`) :
        document.querySelectorAll(`.${gridClass}:not(.${petsGridClass}) .${itemClass}:not(.${itemClass}_${hash})`);

      for (let i = 0; i < boxListToHide.length; i++) {
        boxListToHide[i].classList.add(hiddenClass);
      }
    }
  }

  /**
   * @param {string} hash
   *
   * @return undefined
   */
  const displayExperienceItems = (hash = '') => {
    if (hash.length > 0 && experienceHashList.indexOf(hash) > -1) {
      document.querySelector(`.${itemClass}_${hash}`).classList.remove(hiddenClass);
    } else {
      for (let i = 0; i < experienceHashList.length; i++) {
        document.querySelector(`.${itemClass}_` + experienceHashList[i]).classList.remove(hiddenClass);
      }
    }
  }

  // Init

  // document.addEventListener('DOMContentLoaded', init)
  init();

})();
