module.exports = {

  /**
   * @param {string} className
   * @return {(function(*): void)|*}
   */
  toggleBodyClass: (className) => (e) => {
    e.preventDefault();

    if (document.body.className.indexOf(className) > -1) {
      document.body.classList.remove(className);
    } else {
      document.body.classList.add(className);
    }
  }
}
